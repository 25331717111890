import Swal from "sweetalert2";

export default function errorRequest(history, error) {
  if (error.response && error.response.status === 401) {
    Swal.fire(
      "Token inválido",
      "Por favor, efetue login novamente",
      "info"
    ).then(() => {
      localStorage.clear();
      history.push("/");
    });
  } else if (error.response && error.response.status === 500) {
    Swal.fire(
      "Erro",
      error.response.data.message ||
        "O tipo da imagem está errado. Use apenas imagens JPG ou JPEG",
      "error"
    );
  } else {
    Swal.fire(
      "Erro",
      (error.response && error.response.data.message) ||
        "Ocorreu um erro, verifique os dados e tente novamente!",
      "error"
    );
  }
}
