import React, { useContext, useState } from "react";
import Swal from "sweetalert2";

import { Card, Input } from "reactstrap";

import "./../../../styles.scss";
import FileModal from "./FileModal/index";
import { NewCampaignContext } from "../../../index";
import CountSquare from "./CountSquare/index";
import api from "config/api";
import Loading from "components/Loading";

export default function Model(id) {
  const { state, setState } = useContext(NewCampaignContext);
  const [loading, setLoading] = useState(false);

  function removeImage() {
    Swal.fire({
      title: "Deletar",
      text:
        "Deletar a imagem é uma ação permanente, tem certeza que quer continuar?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Deletar",
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.delete(`/campaign/${id.id}/image`);
          setState({
            ...state,
            backgroundImage: {},
            urlImage: "",
            filename: null,
          });
        } catch (error) {
          if (error.response.status === 401) {
            Swal.fire(
              "Token inválido",
              "Por favor, efetue login novamente",
              "info"
            );
          } else {
            Swal.fire(
              "Erro",
              error.response.data.message ||
                "Ocorreu um erro inesperado, tente novamente",
              "error"
            );
          }
        }
        setLoading(false);
      }
    });
  }

  return (
    <>
      <FileModal />
      {loading && <Loading />}
      <Card
        style={{
          backgroundColor: state.backgroundColor,
          backgroundImage: state.urlImage ? `url(${state.urlImage})` : "",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="d-flex align-items-center justify-content-center card-model modelCard"
      >
        <h3
          style={{ color: state.titleColor }}
          className="text-capitalize apply-font-title"
        >
          {state.title ? state.title : "Título da Campanha"}
        </h3>
        <h4
          style={{ color: state.contentColor }}
          className="text-capitalize apply-font-content"
        >
          {state.content ? state.content : "Subtítulo da Campanha"}
        </h4>
        <div className="position-relative d-flex justify-content-around w-75 apply-font-content">
          <CountSquare unity="dias" value="00" />
          <CountSquare unity="horas" value="23" />
          <CountSquare unity="min" value="49" />
          <CountSquare unity="seg" value="36" />
        </div>
        <button
          onClick={() => setState({ ...state, modal: !state.modal })}
          className="open-modal modelButton"
        >
          <i className="fas fa-upload"></i>
        </button>
        {state.filename ? (
          <button
            onClick={() => removeImage()}
            className="remove-image modelButton"
          >
            <i className="fas fa-trash-alt" />
          </button>
        ) : (
          <Input
            type="color"
            className="model-color input-color"
            value={state.backgroundColor}
            onChange={(e) =>
              setState({ ...state, backgroundColor: e.target.value })
            }
          />
        )}
      </Card>
    </>
  );
}
