import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import 'moment/locale/pt-br';

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import PrivateRoute from 'config/privateRoute';

import AdminLayout from "layouts/Admin.jsx";
import Register from "views/authentication/Register";
import Login from "views/authentication/Login";
import ForgotPassword from "views/authentication/ForgotPassword";
import NotFound from './views/admin/NotFound';
import ResetPassword from './views/authentication/ResetPassword'

export default function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={(props) => <Login {...props} />} />
                <Route
                    path="/forgot-password"
                    render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                    path="/reset-password/:token/:email"
                    component={(props) => <ResetPassword {...props} />}
                />
                <PrivateRoute
                    path="/admin"
                    component={(props) => <AdminLayout {...props} />}
                />
                <Route
                    exact
                    path="*"
                    render={(props) => <NotFound {...props} />}
                />
                <Route path="/register" render={props => <Register {...props} />} />
            </Switch>
        </BrowserRouter>
    )
}