import React, { useContext } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import FormOne from "./FormOne/index";
import ProductsCampaign from "./ProductsCampaign/index";

import { NewCampaignContext } from "../index";
import "./styles.scss";

export default function CampaignTabs() {
  const { idCampaign, activeTab, setActiveTab } = useContext(
    NewCampaignContext
  );

  return (
    <>
      {idCampaign && (
        <Nav tabs className="mb-4">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActiveTab("1");
              }}
            >
              <i className="fas fa-clipboard"></i> Informações
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActiveTab("2");
              }}
            >
              <i className="fas fa-box-open"></i> Produtos
            </NavLink>
          </NavItem>
        </Nav>
      )}

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <FormOne />
        </TabPane>
        {idCampaign && (
          <TabPane tabId="2">
            <Row>
              <Col>
                <ProductsCampaign idCampaign={idCampaign} />
              </Col>
            </Row>
          </TabPane>
        )}
      </TabContent>
    </>
  );
}
