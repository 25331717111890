import React, { useContext } from "react";

import { NewCampaignContext } from "../../../../index";

export default function CountSquare({ unity, value }) {
  const { state } = useContext(NewCampaignContext);
  return (
    <div className="count-container">
      <h3
        style={{
          color: state.numbersColor,
          backgroundColor: state.numbersBackgroundColor,
        }}
        className="text-center count-number"
      >
        {value}
      </h3>
      <small style={{ color: state.contentColor }}>{unity}</small>
    </div>
  );
}
