
import UserProfile from 'views/admin/UserProfile';
import Index from "views/admin/Index.jsx";
import Campaigns from "views/admin/Campaigns";
import { NewCampaign } from "views/admin/NewCampaign";
import NotFound from 'views/admin/NotFound';

var routes = [
  {
    path: "/user-profile",
    name: "Perfil",
    invisible: true,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/index",
    name: "Relâmpago",
    icon: "fas fa-bolt",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/campaigns",
    name: "Campanhas",
    icon: "fas fa-percent",
    component: Campaigns,
    layout: "/admin",
  },
  {
    path: "/campaign",
    name: "Nova Campanha",
    invisible: true,
    component: NewCampaign,
    layout: "/admin",
  },
  {
    path: "*",
    invisible: true,
    component: NotFound,
    layout: "/admin",
  },
];
export default routes;
