import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import { Row, Col, Label, Button } from "reactstrap";
import NuvemShopProductList from "./NuvemShopProductList/index";
import ProductListCampaign from "./ProductListCampaign/index";
import { NewCampaignContext } from "../../index";

import api from "config/api";
import errorRequest from "common/errorRequest";
import SearchBar from "components/SearchBar";

import "./../../styles.scss";

export default function ProductsCampaign(idCampaign) {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const {
    campaignProducts,
    setCampaignProducts,
    nuvemShopProducts,
    products,
    setProducts,
    productRemoveList,
    setProductRemoveList,
    setNuvemShopProducts,
    setLoading,
  } = useContext(NewCampaignContext);

  useEffect(() => {
    fetchNuvemShopProducts();
    editList();
  }, []);

  async function fetchNuvemShopProducts() {
    setLoading(true);
    try {
      setNuvemShopProducts([]);
      const params = { search };
      const { data } = await api.get(`/nuvemShopProduct`, { params });
      setNuvemShopProducts(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  async function editList() {
    const { data } = await api.get(
      `/campaign/${idCampaign.idCampaign}/productCampaign`
    );

    if (data.data.length > 0) {
      const itens = data.data.map((product) => ({
        nuvemShopIdProduct: product.Product.nuvemShopIdProduct,
        name: product.Product.name,
        price: product.Product.price,
        promotionalPrice: product.promotionalPrice,
        urlImageProduct: product.Product.urlImageProduct,
        canonicalUrl: product.Product.canonicalUrl,
        variations: product.Product.Variations.map((Variation) => ({
          nuvemShopIdVariation: Variation.nuvemShopIdVariation,
        })),
      }));

      setCampaignProducts([...campaignProducts, ...itens]);
    } else {
      return;
    }
  }

  function sendProducts() {
    if (products.length > 0) {
      setCampaignProducts([...campaignProducts, ...products]);
      setProducts([]);
      fetchNuvemShopProducts();
    }
  }

  function removeProducts() {
    if (productRemoveList.length > 0) {
      for (let productRemoveItem of productRemoveList) {
        for (let i = 0; i < campaignProducts.length; i++) {
          if (
            campaignProducts[i].nuvemShopIdProduct ===
            productRemoveItem.nuvemShopIdProduct
          ) {
            campaignProducts.splice(i, 1);
          }
        }
      }
      setProductRemoveList([]);
    }
  }

  async function saveProducts() {
    setLoading(true);
    try {
      if (campaignProducts.length > 0) {
        for (let campaignProduct of campaignProducts) {
          if (campaignProduct.promotionalPrice === 0) {
            Swal.fire(
              "Atenção!",
              "Um dos produtos está sem preço promocional, verifique!",
              "warning"
            );
            setLoading(false);
            return;
          }
        }
        const id = idCampaign.idCampaign;
        const finalProductList = { idCampaign: id, products: campaignProducts };
        await api.post(`/campaign/${id}/productCampaign`, finalProductList);

        Swal.fire(
          "Sucesso!",
          "Os produtos da campanha foram alterados.",
          "success"
        ).then(() => {
          history.goBack();
        });
      }
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  return (
    <>
      <Row className="mb-3">
        <Col>
          <Row>
            <Col className="productListTop">
              <SearchBar
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onClick={() => fetchNuvemShopProducts()}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    fetchNuvemShopProducts();
                  }
                }}
              />
              <Button
                onClick={() => sendProducts()}
                color="success"
                className="ml-3"
              >
                Adicionar »
              </Button>
            </Col>
            <Col className="productListTop">
              <Row className="productListTopRow">
                <Label className="noMargin help-title mr-3">
                  Produtos na Campanha
                </Label>
                <Button onClick={() => removeProducts()} color="danger">
                  « Remover seleção
                </Button>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="productList mt-1">
              {nuvemShopProducts ? (
                nuvemShopProducts.map((nuvemProduct) => (
                  <div className="productListStriped" key={nuvemProduct.id}>
                    <NuvemShopProductList
                      id={nuvemProduct.id}
                      name={nuvemProduct.name.pt}
                      originalPrice={nuvemProduct.variants[0].price}
                      productImg={
                        nuvemProduct.images.length
                          ? nuvemProduct.images[0].src
                          : require("../../../../../assets/img/brand/no-image.png")
                      }
                    />
                  </div>
                ))
              ) : (
                  <Label>Não foram encontrados produtos...</Label>
                )}
            </Col>
            <Col className="productList mt-1">
              {campaignProducts.length ? (
                campaignProducts.map((campaignProduct, i) => (
                  <div
                    className="productListStriped"
                    key={campaignProduct.nuvemShopIdProduct}
                  >
                    <ProductListCampaign
                      campaignProduct={campaignProduct}
                      id={campaignProduct.nuvemShopIdProduct}
                      name={campaignProduct.name}
                      originalPrice={campaignProduct.price}
                      promotionalPrice={campaignProduct.promotionalPrice}
                      productImg={campaignProduct.urlImageProduct}
                    />
                  </div>
                ))
              ) : (
                  <Label className="m-3">
                    Ainda não foram adicionados produtos nesta campanha...
                  </Label>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="float-right mr-3">
        <Button
          onClick={() => saveProducts()}
          className="my-4"
          color="primary"
          type="button"
        >
          Salvar Produtos
        </Button>
      </Row>
    </>
  );
}
