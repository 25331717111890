import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import "../../styles.scss";

import CampaignTabs from "./CampaignTabs/index";

import Page from "components/Page";

const NewCampaignContext = React.createContext([{}, () => {}]);
const now = new Date();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function NewCampaign() {
  const query = useQuery();
  const id = query.get("idCampaign");
  const [loading, setLoading] = useState(false);
  const [nuvemShopProducts, setNuvemShopProducts] = useState([]);
  const [campaignProducts, setCampaignProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [productRemoveList, setProductRemoveList] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [idCampaign, setIdCampaign] = useState(id);
  const [state, setState] = useState({
    title: "",
    content: "",
    daily: false,
    titleColor: "#000000",
    contentColor: "#000000",
    backgroundColor: "#ffffff",
    numbersColor: "#ffffff",
    numbersBackgroundColor: "#000000",
    initialDate: now,
    expirationDate: "",
    modal: false,
    backgroundImage: {},
    urlImage: "",
    fontTitleFamily: "Open Sans",
    fontTitleUrl: "",
    fontContentFamily: "Open Sans",
    fontContentUrl: "",
    filename: "",
  });

  return (
    <NewCampaignContext.Provider
      value={{
        state,
        setState,
        loading,
        setLoading,
        activeTab,
        setActiveTab,
        idCampaign,
        setIdCampaign,
        nuvemShopProducts,
        setNuvemShopProducts,
        campaignProducts,
        setCampaignProducts,
        products,
        setProducts,
        productRemoveList,
        setProductRemoveList,
      }}
    >
      <Page title="Nova Campanha" back={true}>
        <CampaignTabs />
      </Page>
    </NewCampaignContext.Provider>
  );
}

export { NewCampaignContext, NewCampaign };
