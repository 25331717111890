import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import FontPicker from "font-picker-react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import { Row, Col, Label, Input, Button } from "reactstrap";
import { NewCampaignContext } from "../../index";

import Model from "./Model/index";

import DatePicker from "components/DatePicker";
import Loading from "components/Loading";
import errorRequest from "common/errorRequest";
import "./styles.scss";
import api from "config/api";

const API_KEY = "AIzaSyDPwq9D5Ih-7dPt4qWW2PPj1dixb0S7Y1E";

export default function FormOne() {
  const {
    state,
    setState,
    loading,
    setLoading,
    idCampaign,
    setIdCampaign,
    setActiveTab,
  } = useContext(NewCampaignContext);
  const history = useHistory();
  const [activeCampaign, setActiveCampaign] = useState(false);
  const [dailyCampaign, setDailyCampaign] = useState(false);

  useEffect(() => {
    if (idCampaign) editForm();
  }, []);

  async function editForm() {
    const { data } = await api.get(`/campaign/${idCampaign}`);
    if (parseInt(idCampaign) === data.data.idCampaign) {
      setActiveCampaign(data.data.active);
      setDailyCampaign(data.data.daily);
      setState({
        initialDate: data.data.initialDate,
        expirationDate: data.data.expirationDate,
        backgroundColor: data.data.backgroundColor,
        content: data.data.content,
        title: data.data.title,
        daily: data.data.daily,
        titleColor: data.data.titleColor,
        contentColor: data.data.contentColor,
        numbersColor: data.data.numbersColor,
        numbersBackgroundColor: data.data.numbersBackgroundColor,
        fontTitleUrl: data.data.fontTitleUrl,
        fontTitleFamily: data.data.fontTitleFamily,
        fontContentUrl: data.data.fontContentUrl,
        fontContentFamily: data.data.fontContentFamily,
        urlImage: data.data.urlImage,
        filename: data.data.filename,
      });
    } else {
      Swal.fire(
        "Erro",
        "Ocorreu um erro ao editar a campanha, tente novamente",
        "error"
      ).then(() => {
        localStorage.clear();
        history.push("/campaigns");
      });
    }
  }

  async function formUpload() {
    if (
      (!dailyCampaign && (!state.initialDate || !state.expirationDate)) ||
      !state.title ||
      !state.content
    ) {
      Swal.fire(
        "Atenção!",
        "Alguns campos não foram preenchidos, verifique!",
        "warning"
      );
      return;
    }

    setLoading(true);
    try {
      if (
        dailyCampaign ||
        (!dailyCampaign &&
          new Date(state.initialDate) < new Date(state.expirationDate))
      ) {
        const formData = new FormData();
        formData.append("initialDate", state.initialDate);
        formData.append("expirationDate", state.expirationDate);
        formData.append("backgroundColor", state.backgroundColor);
        formData.append("content", state.content);
        formData.append("title", state.title);
        formData.append("titleColor", state.titleColor);
        formData.append("contentColor", state.contentColor);
        formData.append("numbersColor", state.numbersColor);
        formData.append("numbersBackgroundColor", state.numbersBackgroundColor);
        formData.append("fontTitleUrl", state.fontTitleUrl);
        formData.append("fontTitleFamily", state.fontTitleFamily);
        formData.append("fontContentUrl", state.fontContentUrl);
        formData.append("fontContentFamily", state.fontContentFamily);
        formData.append("file", state.file);
        formData.append("daily", dailyCampaign);
        if (idCampaign) {
          await api.put(`/campaign/${idCampaign}`, formData);
        } else {
          const { data } = await api.post(`/campaign`, formData);
          setIdCampaign(data.data.idCampaign);
        }

        Swal.fire("Sucesso", "Campanha enviada corretamente", "success");
        setActiveTab("2");
      } else {
        Swal.fire(
          "Erro",
          "A data inicial não pode ser maior que a final",
          "error"
        );
      }
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  function activateCampaign() {
    Swal.fire({
      title: "Ativar campanha",
      text:
        "Essa ação ativará esta campanha, alterando o preço dos produtos cadastrados nela. Tem certeza disso?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Ativar",
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.post(`/campaign/${idCampaign}/active`);
          history.goBack();
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  async function disableCampaign() {
    Swal.fire({
      title: "Desativar campanha",
      text: "Essa ação desativará esta campanha. Tem certeza disso?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Desativar",
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.post(`/campaign/${idCampaign}/desactive`);
          history.goBack();
        } catch (error) {
          errorRequest(history, error);
        }
        setLoading(false);
      }
    });
  }

  async function changeFontTitle(nextFont) {
    const { family, files } = nextFont;
    const fontTitleFamily = family;

    var fontTitleUrl = "";
    if (files) fontTitleUrl = files.regular ? files.regular : files[0];

    setState({ ...state, fontTitleUrl, fontTitleFamily });
  }

  async function changeFontContent(nextFont) {
    const { family, files } = nextFont;
    const fontContentFamily = family;

    var fontContentUrl = "";
    if (files) fontContentUrl = files.regular ? files.regular : files[0];

    setState({ ...state, fontContentUrl, fontContentFamily });
  }

  function dailyCheck() {
    setDailyCampaign(!dailyCampaign)
    // setState({ ...state, initialDate: null, expirationDate: null });
  }

  return (
    <div className="mb-3">
      {loading && <Loading />}
      <Row>
        <Col xs={12} md={6}>
          <Row>
            <Col>
              <Label htmlFor="title">Título:</Label>
              <div className="d-flex">
                <Input
                  id="title"
                  value={state.title}
                  onChange={(e) =>
                    setState({ ...state, title: e.target.value })
                  }
                />
                <Input
                  className="input-color ml-2"
                  type="color"
                  value={state.titleColor}
                  onChange={(e) =>
                    setState({ ...state, titleColor: e.target.value })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Label htmlFor="content">Subtítulo:</Label>
              <div className="d-flex">
                <Input
                  id="content"
                  value={state.content}
                  onChange={(e) =>
                    setState({ ...state, content: e.target.value })
                  }
                />
                <Input
                  className="input-color ml-2"
                  type="color"
                  value={state.contentColor}
                  onChange={(e) =>
                    setState({ ...state, contentColor: e.target.value })
                  }
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Label htmlFor="number-color">Números:</Label>
              <Input
                id="number-color"
                type="color"
                value={state.numbersColor}
                onChange={(e) =>
                  setState({ ...state, numbersColor: e.target.value })
                }
              />
            </Col>
            <Col>
              <Label htmlFor="background-number">Base:</Label>
              <Input
                id="background-number"
                type="color"
                value={state.numbersBackgroundColor}
                onChange={(e) =>
                  setState({ ...state, numbersBackgroundColor: e.target.value })
                }
              />
            </Col>
          </Row>
          {!dailyCampaign && (
            <Row className="mt-4">
              {idCampaign ? (
                <>
                  {!activeCampaign && (
                    <Col>
                      <>
                        <Label htmlFor="initial-date">Data inicial:</Label>
                        <DatePicker
                          value={
                            state.initialDate ? moment(state.initialDate) : ""
                          }
                          onChange={(e) =>
                            setState({ ...state, initialDate: e._d })
                          }
                        />
                      </>
                    </Col>
                  )}
                </>
              ) : (
                  <>
                    <Col>
                      <Label htmlFor="initial-date">Data inicial:</Label>
                      <DatePicker
                        value={state.initialDate ? moment(state.initialDate) : ""}
                        onChange={(e) =>
                          setState({ ...state, initialDate: e._d })
                        }
                      />
                    </Col>
                  </>
                )}
              <Col>
                <Label htmlFor="expire-date">Data final:</Label>
                <DatePicker
                  value={
                    state.expirationDate ? moment(state.expirationDate) : ""
                  }
                  onChange={(e) => setState({ ...state, expirationDate: e._d })}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col>
          <Model id={idCampaign} />
          <Row className="mt-1">
            <Col>
              <Label htmlFor="title">Fonte do Título:</Label>
              <div>
                <FontPicker
                  pickerId="title"
                  apiKey={API_KEY}
                  activeFontFamily={state.fontTitleFamily}
                  onChange={(nextFont) => changeFontTitle(nextFont)}
                />
              </div>
            </Col>
            <Col>
              <Label htmlFor="content">Textos secundários:</Label>
              <div>
                <FontPicker
                  pickerId="content"
                  apiKey={API_KEY}
                  activeFontFamily={state.fontContentFamily}
                  onChange={(nextFont) => changeFontContent(nextFont)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="dailyCol">
              <Label htmlFor="dailyCampaign">
                {dailyCampaign ? `Campanha diária` : `Campanha comum`}
              </Label>
              <label className="switch">
                <input
                  id="dailyCampaign"
                  type="checkbox"
                  checked={dailyCampaign}
                  onChange={() => dailyCheck()}
                />
                <span className="slider round"></span>
              </label>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4 float-right">
        {activeCampaign ? (
          <Button
            className="btn-outline-danger d-flex align-items-center m-4"
            onClick={() => disableCampaign()}
          >
            Desativar campanha
          </Button>
        ) : (
            <>
              {idCampaign && (
                <Button
                  className="btn-outline-primary d-flex align-items-center m-4"
                  onClick={() => activateCampaign()}
                >
                  Ativar campanha
                </Button>
              )}
            </>
          )}
        <Button
          className="d-flex align-items-center mb-4 mt-4 mr-4"
          color="primary"
          onClick={() => formUpload()}
        >
          Salvar
        </Button>
      </Row>
    </div>
  );
}
