import React, { useContext, useState } from "react";
import { Row } from "reactstrap";

import { NewCampaignContext } from "../../../index";

export default function NuvemShopProductList({
  id,
  name,
  originalPrice,
  promotional_price,
  productImg,
}) {
  const {
    products,
    nuvemShopProducts,
    setProducts,
    campaignProducts,
  } = useContext(NewCampaignContext);
  const [checked, setChecked] = useState(false);

  function addProducts() {
    setChecked((checked) => !checked);
    if (!checked) {
      for (let nuvemShopProduct of nuvemShopProducts) {
        if (nuvemShopProduct.id === id) {
          if (
            !checkDoubles(nuvemShopProduct.id, products) &&
            !checkDoubles(nuvemShopProduct.id, campaignProducts)
          ) {
            const variations = nuvemShopProduct.variants.map((variant) => ({
              nuvemShopIdVariation: variant.id,
            }));
            const product = {
              nuvemShopIdProduct: nuvemShopProduct.variants[0].product_id,
              price: nuvemShopProduct.variants[0].price,
              promotionalPrice: 0,
              name: nuvemShopProduct.name.pt,
              urlImageProduct: nuvemShopProduct.images[0].src,
              canonicalUrl: nuvemShopProduct.canonical_url,
              variations,
            };
            setProducts([...products, product]);
          }
        }
      }
    } else {
      for (let i = 0; i < products.length; i++) {
        if (products[i].nuvemShopIdProduct === id) {
          products.splice(i, 1);
        }
      }
      return;
    }
  }

  function checkDoubles(id, array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].nuvemShopIdProduct === id) {
        return true;
      }
    }
    return false;
  }

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        defaultChecked={checked}
        value={id}
        id={id}
        onClick={() => addProducts()}
      />
      <label className="form-check-label" htmlFor={id}>
        <Row className="infoProductsRow">
          <img src={productImg} alt={`foto demonstrativa do produto ${name}`} />
          <div className="productInfo">
            <p className="productName">{name}</p>
            <div className="productPrice">
              <p>
                Preço original: <span>R$ {originalPrice}</span>
              </p>
              {promotional_price && (
                <p>
                  Preço promocional: <span>R$ {promotional_price}</span>
                </p>
              )}
            </div>
          </div>
        </Row>
      </label>
    </div>
  );
}
