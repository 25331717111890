import React from "react";

import Page from "components/Page";

export default function Model() {
  return (
    <Page title="Bem-vindo(a) ao Promoção Relâmpago">
      <p>Escolha o produto, o preço promocional e a data final da promoção e aumente suas conversões.</p>
      <p>Você ainda pode customizar as opções de texos e cores com a cara do seu site!</p>
      <p>Comece a usar agora mesmo</p>
    </Page>
  );
}
