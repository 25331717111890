// import React, { useState, useContext } from "react";
import React, { useState, useContext } from "react";

import { Row, Input } from "reactstrap";

import NumberFormat from "react-number-format";
import { NewCampaignContext } from "../../../index";

export default function ProductListCampaign({
  campaignProduct,
  id,
  name,
  originalPrice,
  promotionalPrice,
  productImg,
}) {
  const {
    productRemoveList,
    setProductRemoveList,
    campaignProducts,
  } = useContext(NewCampaignContext);
  const [checked, setChecked] = useState(false);

  function removeProducts() {
    setChecked((checked) => !checked);
    if (!checked) {
      for (let i of campaignProducts) {
        if (i.nuvemShopIdProduct === id) {
          if (checkDoubles(id, campaignProducts)) {
            setProductRemoveList([...productRemoveList, i]);
          }
        }
      }
    } else {
      for (let i = 0; i < productRemoveList.length; i++) {
        if (productRemoveList[i].nuvemShopIdProduct === id) {
          productRemoveList.splice(i, 1);
        }
      }
      return;
    }
  }

  function checkDoubles(id, array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].nuvemShopIdProduct === id) {
        return true;
      }
    }
    return false;
  }

  function updatePrice(newPrice) {
    campaignProduct.promotionalPrice = newPrice;
  }

  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        value={id}
        id={id + "pC"}
        onClick={() => removeProducts()}
      />
      <label className="form-check-label" htmlFor={id + "pC"}>
        <Row className="infoProductsRow">
          <img src={productImg} alt="foto do produto" />
          <div className="productInfo">
            <p className="productName ">{name}</p>
            <div className="productPrice">
              <p>
                Preço original: <span>R$ {originalPrice}</span>
              </p>
              <div className="productPriceCampaign">
                <p>Promocional:</p>
                <NumberFormat
                  className="priceForm ml--2"
                  decimalScale={2}
                  decimalSeparator=","
                  customInput={Input}
                  prefix={"R$ "}
                  defaultValue={promotionalPrice}
                  onValueChange={(e) => updatePrice(e.value)}
                />
              </div>
            </div>
          </div>
        </Row>
      </label>
    </div>
  );
}
