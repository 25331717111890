import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";

import { Button, Table } from "reactstrap";
import Loading from "components/Loading";
import errorRequest from "common/errorRequest";
import api from "config/api";

import "./styles.scss";
import Page from "components/Page";

function Campaigns() {
  const [campaigns, setCampaigns] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // Busca as campanhas no primeiro load
  useEffect(() => {
    fetchCampaigns();
  }, []);

  // Chamada api para buscar campanhas
  async function fetchCampaigns() {
    setLoading(true);
    try {
      const { data } = await api.get(`/campaign`);

      setCampaigns(data.data);
    } catch (error) {
      errorRequest(history, error);
    }
    setLoading(false);
  }

  // Edita uma campanha
  function editCampaign(id) {
    history.push(`/admin/campaign?idCampaign=${id}`);
  }

  // Deleta uma campanha
  function deleteCampaign(id) {
    Swal.fire({
      title: "Deletar",
      text:
        "Deletar campanha é uma ação permanente, tem certeza que quer continuar?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Deletar",
    }).then(async (res) => {
      if (res.value) {
        setLoading(true);
        try {
          await api.delete(`/campaign/${id}`);

          setCampaigns(
            campaigns.filter((campaign) => {
              return campaign.idCampaign !== id;
            })
          );

          Swal.fire("Sucesso", "Campanha deletada com sucesso", "success");
        } catch (error) {
          if (error.response.status === 401) {
            Swal.fire(
              "Token inválido",
              "Por favor, efetue login novamente",
              "info"
            ).then(() => {
              localStorage.clear();
              history.push("/");
            });
          } else {
            Swal.fire(
              "Erro",
              error.response.data.message ||
                "Ocorreu um erro inesperado, tente novamente",
              "error"
            );
          }
        }
        setLoading(false);
      }
    });
  }
  return (
    <Page title="Suas campanhas cadastradas" classNameCSS="campaignBody">
      {loading && <Loading />}
      <div className="newCampaignButton">
        <Button
          onClick={() => history.push("/admin/campaign")}
          className="d-flex align-items-center m-4 float-right"
          color="primary"
        >
          <i className="ni ni-fat-add" />
          Nova
        </Button>
      </div>
      <Table className="d-none d-md-table campaignTable text-center" responsive>
        <thead>
          <tr>
            <th className="campaignTableTh">Nome</th>
            <th className="campaignTableTh">Subtítulo</th>
            <th className="campaignTableTh">Data inicial</th>
            <th className="campaignTableTh">Data final</th>
            <th className="campaignTableTh">Editar</th>
            <th className="campaignTableTh">Excluir</th>
          </tr>
        </thead>
        <tbody>
          {(!campaigns || campaigns.length === 0) && (
            <tr>
              <td className="text-center" colSpan="8">
                {!loading ? "Nenhum registro foi encontrado..." : ""}
              </td>
            </tr>
          )}
          {campaigns &&
            campaigns.length > 0 &&
            campaigns.map((campaign, i) => (
              <tr
                className={`campaignTr active${campaign.active}`}
                key={campaign.idCampaign}
              >
                <td className="campaignTd">{campaign.title}</td>
                <td className="campaignTd">{campaign.content}</td>
                <td className="campaignTd text-success">
                  {moment(campaign.initialDate).format("DD MMMM YYYY, HH:mm")}
                </td>
                <td className="campaignTd text-danger">
                  {moment(campaign.expirationDate).format(
                    "DD MMMM YYYY, HH:mm"
                  )}
                </td>
                <td className="campaignTd campaignHover">
                  <i
                    onClick={(e) => {
                      e.stopPropagation();
                      editCampaign(campaign.idCampaign);
                    }}
                    className="fas fa-pen"
                  ></i>
                </td>
                <td className="campaignTd campaignHover">
                  <i
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteCampaign(campaign.idCampaign);
                    }}
                    className="fas fa-trash text-danger"
                  ></i>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Page>
  );
}

export default Campaigns;
