import React, { useContext } from "react";
import { Modal, Input } from "reactstrap";

import { NewCampaignContext } from "../../../../index";

export default function FileModal() {
  const { state, setState } = useContext(NewCampaignContext);

  function toggleModal() {
    setState({ ...state, modal: !state.modal });
  }
  return (
    <Modal
      size="md"
      className="variations-modal"
      toggle={() => toggleModal()}
      isOpen={state.modal}
    >
      <div className="modal-header">
        <h3 className="modal-title">Imagem de Background</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggleModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body modal-pictures-body">
        <Input
          onChange={(e) =>
            setState({
              ...state,
              file: e.target.files[0],
              urlImage: URL.createObjectURL(e.target.files[0]),
              filename: "file",
              modal: false,
            })
          }
          type="file"
          accept="image/jpeg, image/jpg"
        />
      </div>
    </Modal>
  );
}
